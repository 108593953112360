.create-sign-container{
  .create-sign-canvas-container{
    padding: 20px 0px 40px 0px;
    display: flex;
    justify-content: center;
    canvas{
      border: 1px solid gray;
    }
  }
}

@primary-color: #1DA57A;