@import url(../../styles/variables.less);
.signer-form-sec {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-form-container {
        border: 1px solid @form-border-color;
        border-radius: 3px;
        width: 50%;
        padding: 50px;
        margin: 0 auto 0px auto;
    }
    .signer-btn {
        text-align: center;
        .ant-form-item {
            margin: 0;
        }
    }
    .form-field-sec {
        label {
            font-size: 14px;
            font-weight: 500;
        }
    }
    @media all and (max-width: 1199px) {
        .custom-form-container {
            width: 60%;
        }
    }
    @media all and (max-width: 991px) {
        .custom-form-container {
            width: 70%;
        }
    }
    @media all and (max-width: 767px) {
        .custom-form-container {
            width: 80%;
        }
    }
    @media all and (max-width: 479px) {
        .custom-form-container {
            width: 90%;
            padding: 20px;
            .form-field-sec {
                input {
                    padding-left: 10px;
                }
            }
        }
    }
}

@primary-color: #1DA57A;