@import "../../../styles/variables.less";
@import "../../../styles/mixin.less";

#template-builder-middle-side-main-container {
    background-color: @template-builder-bg-color;
    height: calc(100vh - 87px);
    overflow-y: auto;
    padding-bottom: 40px;
    box-sizing: border-box;

    .scroll-css-global();

    #template-builder-middle-side {
        width: 612px;
        flex-basis: 612px;
        height: 792px;
        // background-image: url("../../../assets/images/form-image.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;
        z-index: 3;
        .box-draggable {
            position: absolute;
            cursor: move;
            // height: 67px;

            .ant-checkbox-inner,
            .ant-radio-inner {
                width: 12px;
                height: 12px;
            }
            .ant-radio-inner::after {
                width: 14px;
                height: 14px;
                margin-top: -7px;
                margin-left: -7px;
                border-color: rgb(0 0 0) !important;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
                left: 1px;
                border-color: rgb(0 0 0) !important;
            }

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            &:focus-visible {
                outline: none !important;
            }

            &[tabindex="0"] {
                cursor: move;
                user-select: none;
                outline: none;

                &:focus-visible {
                    outline: none !important;
                }

                &:focus {
                    outline: none !important;
                }
            }
        }
        .checkbox-item,
        .radiobtn-item {
            min-width: auto !important;
            min-height: auto !important;
            outline: 5px solid @resize-able-item-color;
        }

        .resize-able-item-custom-selected.radiobtn-item {
            border: none !important;
            outline-color: #2463d1;
            outline-width: 2px;
        }
        .resize-able-item-custom-selected.checkbox-item {
            border: none !important;
            outline-color: #2463d1;
            outline-width: 2px;
        }

        .resize-able-item-custom {
            text-align: center;
            background-color: @resize-able-item-color;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-x: hidden;
            overflow-y: hidden;
            opacity: 0.8;
            // &::-webkit-scrollbar {
            //   width: 20px;
            // }
            // &::-webkit-scrollbar-track {
            //   background-color: transparent;
            // }
            // &::-webkit-scrollbar-thumb {
            //   background-color: #d6dee1;
            // }
            // &::-webkit-scrollbar-thumb {
            //   background-color: #d6dee1;
            //   border-radius: 20px;
            // }
            // &::-webkit-scrollbar-thumb {
            //   background-color: #d6dee1;
            //   border-radius: 20px;
            //   border: 6px solid transparent;
            //   background-clip: content-box;
            // }
            // &::-webkit-scrollbar-thumb:hover {
            //   background-color: #a8bbbf;
            // }
        }

        .resize-able-item-custom-selected {
            border: 2px solid #2463d1;
        }

        .disable-border-resize-able {
            border: none !important;
        }
    }

    .page-no-container {
        &:first-child {
            margin: 10px 0px 330px 0px;
        }
        text-align: center;
        // margin: 10px 0px;
        margin: 330px 0px 330px 0px;
        h4 {
            font-weight: 700;
        }
    }

    .main-template-middle-container {
        position: relative;
        width: 612px;
        height: 792px;
        margin: 0 auto;
        // transform: scale(1.8);
        .lazy-load-image-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            img {
                width: 100%;
            }
        }
    }
}

@primary-color: #1DA57A;