@import url("../../styles/variables.less");
.custom-table-grid-view-container {
  background-color: @white-color;
  padding: 20px 20px 10px 20px;
  .ant-table-title {
    margin-bottom: 20px;
    margin-top: 10px;
    z-index: 999999;
    position: relative;
    h5 {
      color: @table-title-color;
      font-size: 16px;
      margin: 0px;
      line-height: normal;
    }
    .title-sec {
      display: flex;
      align-items: center;
    }
    .filter-btn {
      margin-left: 5px;
      border-radius: 5px;
      padding: 9px 18px;
      line-height: normal;
      height: auto;
      svg {
        margin-right: 5px;
        margin-top: 4px;
      }
    }
    .filter-form {
      .ant-form-item-row {
        flex-direction: column;
      }
      .ant-form-item-label {
        text-align: left;
      }
      .custom-form-item {
        margin-right: 10px;
      }
      input {
        padding: 9px 11px;
        min-height: 29px;
        border-radius: 6px;
      }
      .ant-picker {
        border-radius: 6px;
        padding: 0;
        padding-right: 11px;
      }
      button {
        &.ant-btn {
          padding: 9px 20px;
          border-radius: 6px;
          height: auto;
        }
      }
    }
  }
  .custom-table-container-with-hover {
    .ant-table-tbody tr:hover {
      cursor: pointer;
    }
  }
  .custom-table-container {
    .ant-table-content {
      .ant-pagination {
        .ant-table-pagination {
          li {
            margin: 0px;
          }
        }
      }
      width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 3px;
        background-color: @grid-box-shadow;
        -webkit-border-radius: 100px;
      }

      &::-webkit-scrollbar:hover {
        background-color: @scrollbar-color;
      }

      &::-webkit-scrollbar-thumb:vertical {
        background: @scrollbar-thumb-color;
        -webkit-border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb:vertical:active {
        background: @scrollbar-thumb-color-active;
        -webkit-border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb:horizontal {
        background: @scrollbar-thumb-color;
        -webkit-border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb:horizontal:active {
        background: @scrollbar-thumb-color-active;
        -webkit-border-radius: 100px;
      }
    }
    .ant-table {
      border-radius: 0px !important;
    }
    .ant-table-title {
      padding: 0px;
      border: 0px !important;
    }
    .ant-table-thead > {
      tr > {
        th {
          color: @table-th-text-color;
          font-weight: 500;
          background-color: @white-color;
        }
      }
    }
    .ant-table.ant-table-bordered > {
      .ant-table-container {
        border: 0px;
        > .ant-table-content {
          > table {
            thead {
              th {
                border-right: 0px;
              }
            }
            > tbody {
              > tr {
                > td {
                  border-right: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  // Table Header CSS
  .table-top-btn-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  // global css

  .btnEdit {
    background-color: @edit-btn;
  }
  .btnEdit:hover,
  .btnEdit:focus {
    color: @white-color;
  }

  .btnSucess {
    background-color: @success-Color;
  }

  .btnDanger {
    background-color: @danger-color;
  }
  .btnYellow {
    background-color: @yellow-Color;
  }

  .btnDownload {
    background-color: @gray-color;
  }

  .nameList {
    color: @custom-link-color;
  }

  .dangerText {
    color: @danger-color;
  }

  .success {
    color: @success-Color;
  }

  .textWhite {
    color: @white-color;
  }
  .action-col {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      svg {
        padding: 0px;
        border: 0px;
        font-size: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .btnEdit {
      background-color: @primary-color;
    }
    .btnDanger {
      i {
        &.tod-refund {
          font-size: 14px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;