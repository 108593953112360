.collapse__box {
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  transform: scaleY(0);
  transform-origin: top;
  height: 0;
  &.active {
    height: 130px;
    transform: scaleY(1);
  }
}

@primary-color: #1DA57A;