@import "/src/styles/variables.less";

.finish-btn-sec-mixin {
    position: sticky;
    top: 0;
    background-color: #fff;
    width: 100%;
    margin: 0;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    min-width: 612px;
}

.template-form-view-container-new-main {
    .card-container-signature {
        position: relative;
        .cross-btn-signature {
            position: absolute;
            right: -21px;
            top: -12px;
            background: #d9d9d9;
            border-radius: 50%;
            padding: 7px 7px;
            z-index: 9999;

            &:hover {
                cursor: pointer;
            }
        }
    }
    padding: 0px;
    overflow: hidden;
    .ant-picker {
        display: flex;
        align-items: flex-start;
        input {
            margin-left: 0px !important;
        }
        .ant-picker-suffix {
            margin-right: 0px !important;
        }
    }

    .envelope-btn-finish-later {
        margin-left: 40px !important;
    }
    .template-form-view-container-new {
        width: 50%;
        margin: 0 auto;
        position: relative;
        .pdf-sec {
            margin-bottom: 20px;
            width: 612px;
            position: relative;
            .lazy-load-image-background {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                img {
                    width: 100%;
                }
            }
        }
        .page-no-container {
            text-align: center;
            width: 612px;
        }
        #finish-btn-sec {
            width: 612px;
            margin: 0 auto;
        }
        .create-signature-container {
            height: 65px;
            width: 100px;
            border: 1px solid black;
            text-align: center;
            box-sizing: border-box;
            border-style: dashed;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .create-signature-container:hover {
            cursor: pointer;
        }
        #left-side {
            border: 1px solid green;
            width: 612px;
            flex-basis: 612px;
            height: 792px;
            background-image: url("../../assets/images/form-image.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .box-draggable {
                position: absolute;
                cursor: move;
                height: 67px;
            }
        }
        #right-side {
            position: absolute;
            top: 30px;
            right: -29px;
        }
        .box {
            position: absolute;
            cursor: move;
            color: black;
            max-width: 215px;
            border-radius: 5px;
            padding: 1em;
            margin: auto;
            user-select: none;
            background-color: rgba(0, 128, 0, 0.324);
        }
        .ant-card-head-title {
            font-size: 14px;
            padding: 3px 0px 3px 10px !important;
        }
        .ant-card {
            width: 120px;
            background-color: transparent;

            border-color: transparent;
        }
        .ant-card-head {
            padding: 0px !important;
            min-height: auto !important;
        }
        .ant-card-head-title:hover {
            cursor: move;
        }
        .ant-card-body {
            padding: 0px !important;
        }
        .ant-input {
            width: 120px;
            padding: 0px 5px;
            // line-height: 0px;
        }
        .ant-input-group-addon {
            background-color: transparent;
            border-color: transparent;
            padding: 0px;
        }
        .common-button {
            margin-bottom: 10px;
        }
        .ant-card-extra {
            padding: 0px 10px 0px 0px;
            margin-top: -5px;
        }
        .card-x-btn {
            font-weight: 500;
        }
        .card-x-btn:hover {
            cursor: pointer;
        }
        .image-draggable-container {
            position: relative;
            #image-overlay {
                z-index: 1;
                position: absolute;
                height: 50px;
                width: 90px;
                top: 0px;
            }
            img {
                z-index: -1;
            }
            .cross-btn {
                position: absolute;
                top: -25px;
                z-index: 2;
                // width: 90px;
                text-align: right;
            }
            .cross-btn:hover {
                cursor: pointer;
            }
        }

        .pagination-container {
            width: 612px;
            display: flex;
            justify-content: space-around;
            margin-left: 182px;
        }
    }
    .pdf-middle-col {
        .ant-empty {
            &.ant-empty-normal {
                &.ant-empty-small {
                    text-align: center;
                    font-size: 10px;
                    width: 100%;
                    .ant-empty-image {
                        height: auto;
                        line-height: normal;
                        margin-bottom: 0px;
                    }
                    svg {
                        &.ant-empty-img-simple {
                            width: 65% !important;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 1880px) {
        .right-side-col,
        .left-side-col {
            padding-left: 15px !important;
            padding-left: 15px !important;
        }
    }
    @media all and (max-width: 880px) {
        .left-side-col,
        .right-side-col {
            display: none;
        }
        .pdf-middle-col {
            max-width: 100%;
            flex: 1;
        }
    }
}

#envelope-builder-middle-side-main-container {
    .box-draggable {
        .ant-checkbox-inner,
        .ant-radio-inner {
            width: 12px;
            height: 12px;
        }
        .ant-radio-inner::after {
            width: 10px;
            height: 10px;
            margin-top: -5px;
            margin-left: -5px;
            border-color: rgb(0 0 0) !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
            left: 1px;
            border-color: rgb(0 0 0) !important;
        }

        .ant-checkbox-disabled .ant-checkbox-inner {
            background-color: #dadada;
            border-color: #000000 !important;
        }

        .ant-radio.ant-radio-disabled .ant-radio-inner {
            background-color: #dadada;
            border-color: #000000 !important;
        }
    }
    .box-draggable.react-draggable {
        position: absolute;
        top: 0;
        left: 0;
    }

    background-color: @template-builder-bg-color;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    #template-builder-middle-side {
        width: 612px;
        flex-basis: 612px;
        height: 792px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;
        z-index: 3;
        .box-draggable {
            position: absolute;
            cursor: move;
        }
        .resize-able-item-custom {
            text-align: center;
            background-color: @resize-able-item-color;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .resize-able-item-custom-selected {
            border: 1px solid #2463d1;
        }
    }

    .page-no-container {
        &:first-child {
            margin: 10px 0px 330px 0px;
        }
        text-align: center;
        margin: 330px 0px 330px 0px;
        h4 {
            font-weight: 700;
        }
    }

    .main-template-middle-container {
        position: relative;
        width: 612px;
        height: 792px;
        margin: 0 auto;
        // transform: scale(1.8);
        .lazy-load-image-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            img {
                width: 100%;
            }
        }
    }
    .ant-select-status-error {
        .ant-checkbox-inner,
        .ant-radio-inner {
            border-color: #ff4d4f !important;
        }
    }
    .sign-error {
        border-color: #ff4d4f !important;
    }
    #finish-btn-sec {
        .finish-btn-sec-mixin();

        button {
            margin: 0px;
        }
    }
    @media all and (max-width: 1580px) {
        .page-no-container {
            margin: 170px 0px 170px 0px;
            &:first-child {
                margin: 10px 0px 170px 0px;
            }
        }
        &.form-view-inner-sec {
            .page-no-container:first-child {
                margin: 10px 0px 10px 0px;
            }
            .page-no-container {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 1199px) {
        .page-no-container {
            margin: 10px 0px 90px 0px;
            &:first-child {
                margin: 10px 0px 90px 0px;
            }
        }
    }
    @media all and (max-width: 1050px) {
        .page-no-container {
            margin: 10px 0px 10px 0px;
            &:first-child {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 880px) {
        .page-no-container {
            margin: 10px 0px 10px 0px;
            &:first-child {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 612px) {
        .page-no-container:first-child {
            margin-top: 10px !important;
        }
        #finish-btn-sec {
            button {
                margin: 0px;
            }
        }
    }
}

#envelope-view-only-builder-middle-side-main-container {
    .box-draggable {
        .ant-checkbox-inner,
        .ant-radio-inner {
            width: 12px;
            height: 12px;
        }
        .ant-radio-inner::after {
            width: 10px;
            height: 10px;
            margin-top: -5px;
            margin-left: -5px;
            border-color: rgb(0 0 0) !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
            left: 1px;
            border-color: rgb(0 0 0) !important;
        }

        .ant-checkbox-disabled .ant-checkbox-inner {
            background-color: #dadada;
            border-color: #000000 !important;
        }

        .ant-radio.ant-radio-disabled .ant-radio-inner {
            background-color: #dadada;
            border-color: #000000 !important;
        }
    }
    .box-draggable.react-draggable {
        position: absolute;
        top: 0;
        left: 0;
    }

    background-color: @template-builder-bg-color;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
    overflow-x: hidden;
    margin-top: auto;
    #template-builder-middle-side {
        width: 612px;
        flex-basis: 612px;
        height: 792px;
        // background-image: url("../../../assets/images/form-image.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;
        z-index: 3;
        .box-draggable {
            position: absolute;
            cursor: move;
        }
        .resize-able-item-custom {
            text-align: center;
            background-color: @resize-able-item-color;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .resize-able-item-custom-selected {
            border: 1px solid #2463d1;
        }
    }

    .page-no-container {
        &:first-child {
            margin: 10px 0px 330px 0px;
        }
        text-align: center;
        margin: 330px 0px 330px 0px;
        h4 {
            font-weight: 700;
        }
    }

    .main-template-middle-container {
        position: relative;
        width: 612px;
        height: 792px;
        margin: 0 auto;
        .lazy-load-image-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            img {
                width: 100%;
            }
        }
    }
    .ant-select-status-error {
        .ant-checkbox-inner,
        .ant-radio-inner {
            border-color: #ff4d4f !important;
        }
    }
    .sign-error {
        border-color: #ff4d4f !important;
    }
    #finish-btn-sec {
        .finish-btn-sec-mixin();

        button {
            margin: 0px;
        }
    }
    @media all and (max-width: 1580px) {
        .page-no-container {
            margin: 170px 0px 170px 0px;
            &:first-child {
                margin: 10px 0px 170px 0px;
            }
        }
        &.form-view-inner-sec {
            .page-no-container:first-child {
                margin: 10px 0px 10px 0px;
            }
            .page-no-container {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 1199px) {
        .page-no-container {
            margin: 10px 0px 90px 0px;
            &:first-child {
                margin: 10px 0px 90px 0px;
            }
        }
    }
    @media all and (max-width: 1050px) {
        .page-no-container {
            margin: 10px 0px 10px 0px;
            &:first-child {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 880px) {
        .main-template-middle-container {
            transform: scale(100%) translate(0%, 0px);
            margin-left: auto;
            margin-right: auto;
        }
        .page-no-container {
            margin: 10px 0px 10px 0px;
            &:first-child {
                margin: 10px 0px 10px 0px;
            }
        }
    }
    @media all and (max-width: 612px) {
        .page-no-container:first-child {
            margin-top: 10px !important;
        }
        #finish-btn-sec {
            .finish-btn-sec-mixin();
            button {
                margin: 0px;
            }
        }
    }
    // .main-pdf-sec {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
    // @media all and (max-width: 768px) {
    //     .main-template-middle-container {
    //         transform: scale(0.9);
    //         margin-top: -40px;
    //     }
    //     .page-no-container {
    //         margin: 10px 0px 10px 0px;
    //         &:first-child {
    //             margin: 10px 0px 10px 0px;
    //         }
    //         &:not(:first-child) {
    //             margin-top: -22px;
    //         }
    //     }
    // }
}


@primary-color: #1DA57A;