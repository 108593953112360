.create-signed-date-container {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 8px;
    span {
        // padding-left: 10px;
    }
}


@primary-color: #1DA57A;