.form-view-dropdown-css {
    .ant-select-selection-placeholder {
        position: absolute;
        top: -3px;
    }
    // .ant-select-open {
    //     z-index: 5000;
    //     .ant-select-dropdown {

    //     }
    // }

    .custom-menu-render-class {
        z-index: 60000;
    }
}


@primary-color: #1DA57A;