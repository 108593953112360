@import url(../../styles/variables.less);

.breadcurmbsSec {
  margin-bottom: 30px;
  background-color: @white-color;
  padding: 13px 26px 13px 26px;
  .title-icon-sec {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .kl-custom-heading {
    .primary-heading {
      margin-bottom: 0px !important;
      line-height: normal;
      color: @breadcrumb-title;
      font-size: 12px;
    }
  }
  .breadcurmbs-icon-sec {
    &::after {
      content: "/";
      padding-left: 10px;
      margin-right: 10px;
    }
  }
  .breadcrumb-btn-sec {
    .custom-button-container {
      text-align: right;
    }
    svg {
      margin-right: 10px;
    }
  }
}

@primary-color: #1DA57A;