@import "./variables.less";

.scroll-css-global {
    &::-webkit-scrollbar {
        width: 1em;
    }

    &::-webkit-scrollbar-track {
        background-color: darkgrey;
    }

    &::-webkit-scrollbar-thumb {
        background-color: @loader-color;
        outline: 1px solid @loader-color;
    }
}

