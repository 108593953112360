.kl-custom-select-container {
    display: block !important;
    .ant-select {
        font-size: inherit;
    }

    .ant-select-selector {
        padding: 4.5px 0px 4.5px 18px !important;
        display: block;
        height: 100% !important;
    }
    .ant-select-dropdown {
        left: 0px !important;
        top: 25px !important;
        font-size: inherit;
    }
    .ant-select-item {
        font-size: inherit;
    }
    .ant-select-item-option-content {
        font-size: inherit;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: 400;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        height: 30px !important;
        line-height: 40px !important;
        // line-height: 12px !important;
        font-size: inherit;
        white-space: normal;
        text-overflow: unset;
    }

    .small {
        .ant-select-selector {
            padding: 3px 0px 3px 18px !important;
            display: block;
            height: 40px !important;
        }

        .ant-select-selection-placeholder {
            line-height: 30px !important;
            padding-right: 28px;
        }

        .ant-select-selection-item {
            padding-right: 28px;
        }
    }
    .ant-select-selection-search-input {
        height: 100% !important;
    }
}


@primary-color: #1DA57A;