.custom-layout-container {
  .custom-main-content {
    margin: 0 16px;
  }
  .custom-inner-content {
    padding: 24px;
    min-height: 360px;
  }
  .custom-bread-crumb-container {
    margin: 16px 0;
  }
  min-height: 100vh;
  .main-sidebar-heading {
    color: white;
    text-align: center;
  }
  #components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .site-layout {
    min-height: 100vh;
  }
  .custom-main-layout-footer {
    text-align: center;
  }
}

@primary-color: #1DA57A;