.template-types-container {
    display: flex;
    // flex-direction: column;
    gap: 0.5rem;
    margin: 12px 0px;
    width: 500px;
    align-items: center;
}
.time-dropdown {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: right center;
    border: none;
    padding: 2px 20px 2px 2px;
    width: auto;
    min-width: 70px;
    cursor: pointer;
}

.time-dropdown:focus {
    outline: none;
}

/* Hide the first (empty) option when dropdown is closed */
.time-dropdown option[value=""] {
    display: none;
}

/* Remove the default arrow in IE */
.time-dropdown::-ms-expand {
    display: none;
}
@primary-color: #1DA57A;