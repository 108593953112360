.kl-custom-input-number-container {
    input {
        padding: 14px 0px 14px 19px;
    }
    .ant-input-number {
        width: 100%;
    }
}


@primary-color: #1DA57A;