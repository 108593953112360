@import url(../../styles/variables.less);
.thankyou {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
        color: @loader-color;
        font-size: 60px;
    }
    .kl-custom-heading {
        .primary-heading {
            font-size: 50px;
            color: @form-bg-color;
        }
    }
}

@primary-color: #1DA57A;