@import url(../../styles/variables.less);
.kl-custom-select-container {
    select {
        &.form-view-dropdown-css {
            border: 1px solid @form-border-color;
            border-radius: 2px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &.error {
                border-color: @error-border;
            }
            &:hover {
                border-color: @hover-field-border;
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
    display: block !important;
}

@primary-color: #1DA57A;