.form-view-text-area {
    resize: none;
    overflow: hidden;
    // font-size: 8px !important;
    padding: 0px !important;
}

.m-0 {
    margin: 0 !important;
}
.line-height {
    line-height: 1.1;
}


@primary-color: #1DA57A;