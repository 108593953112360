#template-builder-right-side {
    overflow-y: auto;

    .queryNamesDropdown {
        .ant-form-item-control {
            width: 100%;
        }
        .kl-custom-select-container .ant-select-dropdown {
            top: 42px !important;
        }
    }
    .kl-custom-select-container .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .kl-custom-select-container .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        line-height: 31px !important;
    }
    .save-back-btn-sec {
        padding: 20px 0px;
    }
    .repeter-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px dashed #d9d9d9;
        margin-bottom: 10px;
        padding-top: 10px;
        box-sizing: border-box;
    }
    .field-sec {
        margin-right: 10px;
    }
    .field-sec-signature-signed-container {
        background-color: #d9d9d938;
        padding: 5px 11px;
        border-top: 0px;
        .field-sec-signature-signed {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex: 1;
            p {
                margin: 0;
            }
        }
    }
    .add-field-btn {
        padding: 5px;
        font-size: 12px;
    }
    .custom-form-item {
        .ant-form-item-row {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.field-is-not-selected-template-right-side {
    display: flex;
    align-items: center;
    justify-content: center;
}


@primary-color: #1DA57A;