@import "../../../assets/vendor/variable.less";

.create-signature-from-name-container {
    .label-for-signature {
        margin-right: 20px;
    }
    .ant-input {
        width: 100%;
    }
    .signature-name {
        font-family: @font-family;
        font-size: 30px;
        font-weight: 600;
        margin-top: 40px;
    }
}

@primary-color: #1DA57A;