.custom-full-page-loader {
    width: 100%;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mt-4 {
        margin-top: 30px;
    }
}

@primary-color: #1DA57A;