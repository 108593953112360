.custom-dropdown {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 8px 16px;
    // background-color: #f0f0f0;
    // border: 1px solid #ccc;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
}

.dropdown-toggle:hover {
    background-color: transparent;
}

.dropdown-menu {
    position: absolute;
    top: 107%;
    left: 50%;
    width: auto;
    margin-top: -5px;
    padding: 0;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    translate: -50%;
}

.dropdown-menu li {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.dropdown-menu li button {
    font-size: 8px !important;
    padding: 3px 9px;
    height: 100%;
}

.dropdown-menu-top-postion {
    top: -128% !important;
}


@primary-color: #1DA57A;