.custom-form-item {
  .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  label {
    &::before {
      content: none !important;
    }
  }
}

@media (max-width: 1024px) {
  .custom-form-item {
    label {
      font-size: 18px;
    }
  }
}

@primary-color: #1DA57A;