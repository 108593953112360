@import "../../../styles/variables.less";

.recent-sign-container {
  .custom-radio-group-container {
    margin-bottom: 15px;
  }
  .ant-radio-wrapper {
    border: 3px solid @gray-color;
    padding: 5px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    transition: all ease-in-out 0.3s;
    > .ant-radio {
      display: none;
    }
    &:hover {
      border-color: @primary-color;
    }
    .img-check {
      svg {
        color: @primary-color;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 18px;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        visibility: hidden;
      }
      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
        display: block;
      }
    }
  }
  .ant-radio-wrapper-checked {
    .img-check {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@primary-color: #1DA57A;