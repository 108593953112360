@import "../../styles/variables.less";
@import "../../styles/mixin.less";

.template-builder-view-container-new {
    // display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        line-height: 12px !important;
    }
    #left-side {
        position: relative;
        border: 1px solid @green;
        flex-basis: 612px;
        height: 850px;
        background-image: url("../../assets/images/form-image.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;

        .box-draggable {
            position: absolute;
            cursor: move;
            height: 67px;
            background: @green;
        }
        .formatting-class {
            > span {
                color: inherit;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                input {
                    color: inherit;
                    font-family: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }
    }

    
   
    .template-builder-view-container-new {
        .dnd-dragging-item {
            cursor: grabbing !important;
            pointer-events: none;
            position: fixed;
            z-index: 1000;
            margin: 0;
            left: 0;
            top: 0;
            will-change: transform;
        }
    }
    
    .field-dragging {
        opacity: 0.8;
        cursor: grabbing;
    }
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    
    .context-menu {
        user-select: none;
    }
    
    .context-menu:hover {
        background: #f5f5f5;
    }
    .pdf-middle-col {
        position: relative;
        
        .drop-target {
            position: relative;
            min-height: 100%;
            transition: background-color 0.2s ease;
        }
    
        .field-dragging {
            position: fixed;
            pointer-events: none;
            z-index: 1000;
            opacity: 0.8;
            cursor: grabbing;
        }
    }
    .drop-target {
        transition: background-color 0.2s ease;
        min-height: 100vh;
        width: 100%;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
        }
    }
    
    .pdf-middle-col {
        position: relative;
    }
    .template-builder-card-side {
        background-color: @white-color;
        height: 650px;
        padding: 30px 15px;
        h4 {
            font-size: 16px;
            color: @table-title-color;
            font-weight: 500;
            margin-bottom: 12px;
            margin-left: 11px;
        }
        .kl-custom-search-container {
            margin-bottom: 10px;
            display: block;
            .ant-input-group {
                width: 100%;
            }
            .ant-input-affix-wrapper {
                border: 1px solid @light-gray;
                border-radius: 2px !important;
                color: @gray-1;
            }
        }
        ul {
            padding: 0px 10px;
            list-style: none;
            li {
                .common-button {
                    background-color: transparent;
                    border: 1px solid @light-gray;
                    border-radius: 2px;
                    width: 100%;
                    text-align: left;
                    padding: 8px 15px;
                    height: auto;
                    display: flex;
                    align-items: center;
                    span {
                        color: @gray-1;
                        font-size: 12px;
                        font-weight: 500;
                    }
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .field-option-side {
        .kl-custom-select-container {
            .ant-select-single.ant-select-show-arrow {
                .ant-select-selection-placeholder {
                    height: auto !important;
                    line-height: 30px !important;
                }
            }
            .ant-select-selector {
                padding-left: 11px;
            }
        }
        .color-select {
            .ant-col {
                width: 100%;
                label {
                    width: 100%;
                }
            }
            .ant-input {
                padding: 0px 2px !important;
            }
        }
        .ant-input {
            width: 100%;
            padding: 8px 5px;
        }
        .ant-form-item {
            margin-bottom: 15px;
            &.mb-0 {
                margin-bottom: 0;
            }
        }
        .char-limit {
            .ant-form-item-label {
                margin-left: 5px;
            }
            .ant-row {
                display: flex;
                flex-direction: row-reverse;
            }
            .ant-input-number-in-form-item {
                width: 100%;
            }
        }
        .font-btn-sec {
            display: flex;
            align-items: center;
            .custom-form-item {
                margin-right: 6px;
            }
            .ant-checkbox {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: transparent;
                top: 0;
                left: 0;
                // &.ant-checkbox-checked {
                //   background-color: @primary-color;
                // }
                .ant-checkbox-inner {
                    display: none;
                }
            }

            .ant-checkbox-wrapper {
                padding: 4px 7px;
                border: 1px solid #d9d9d9;
            }
        }
        .custom-form-container {
            height: 100%;
            form#basic {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                input#basic_color {
                    padding: 0 2px;
                }
                .ant-collapse-header-text {
                    font-weight: bold;
                }
                .save-back-btn-sec {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .ant-btn {
                        width: 100%;
                    }
                    .secondary-btn {
                        margin-right: 5px;
                    }
                    .custom-form-item {
                        flex-basis: 40%;
                        .ant-form-item {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    #right-side-new {
        width: 100%;
        flex-basis: 100%;
    }
    .box {
        position: absolute;
        cursor: move;
        color: @black-color;
        max-width: 215px;
        border-radius: 5px;
        padding: 1em;
        margin: auto;
        user-select: none;
        background-color: @border-green-color;
    }
    .ant-card-head-title {
        font-size: 14px;
        padding: 3px 0px 3px 10px !important;
    }
    .ant-card {
        // width: 120px;
        background-color: transparent;
        line-height: 0.5;
        border: 0;
        font-size: 8px !important;

        .ant-radio-wrapper {
            line-height: 0px !important;
        }
        .ant-picker {
            font-size: inherit;
            padding: 0;
            // border: none !important;
            input {
                margin-left: 4px;
                font-size: 8px !important;
            }
            .ant-picker-suffix {
                margin-right: 4px;
            }
        }
        .ant-picker-focused {
            box-shadow: none !important;
        }
        input {
            font-size: inherit;
            // border: none !important;
        }
        textarea {
            font-size: inherit;
            // border: none !important;
        }
        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none !important;
        }
    }
    .ant-card-head {
        padding: 0px !important;
        min-height: auto !important;
    }
    .ant-card-head-title:hover {
        cursor: move;
    }
    .ant-card-body {
        padding: 0px !important;
    }
    .ant-input {
        width: 120px;
        // padding: 0px 5px;
        // line-height: 10px;
        line-height: 10px;
        overflow-y: scroll;

        .scroll-css-global();

        &::-webkit-scrollbar {
            width: 3px;
        }
    }
    .field-container {
        .ant-card-body {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cross-btn {
            position: static;
        }
        &.textarea-container {
            width: 500px;
            textarea.ant-input {
                width: 100%;
                resize: none;
            }
        }
    }

    .ant-input-group-addon {
        background-color: transparent;
        border-color: transparent;
        padding: 0px;
    }
    .common-button {
        margin-bottom: 10px;
    }
    .ant-card-extra {
        padding: 0px 10px 0px 0px;
        margin-top: -5px;
    }
    .card-x-btn {
        font-weight: 500;
    }
    .card-x-btn:hover {
        cursor: pointer;
    }
    .image-draggable-container {
        position: relative;
        #image-overlay {
            z-index: 1;
            position: absolute;
            height: 50px;
            width: 90px;
            top: 0px;
        }
        img {
            z-index: -1;
        }
        .cross-btn {
            position: absolute;
            top: -25px;
            z-index: 2;
            text-align: right;
        }
        .cross-btn:hover {
            cursor: pointer;
        }
    }
    .create-signature-container-relative {
        position: relative;
    }
    .cross-btn {
        position: absolute;
        top: -20px;
        right: 18px;
    }
    .cross-btn:hover {
        cursor: pointer;
    }
    .create-signature-container {
        height: 65px;
        border: 1px solid @black-color;
        text-align: center;
        box-sizing: border-box;
        border-style: dashed;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media all and (max-width: 1880px) {
        #template-builder-middle-side-main-container {
            .main-template-middle-container {
                // transform: scale(1.3);
            }
            .page-no-container:first-child {
                margin: 10px 0px 130px 0px;
            }
            .page-no-container {
                text-align: center;
                margin: 130px 0px 130px 0px;
            }
        }
        .template-builder-card-side {
            padding: 20px 8px;
            ul {
                padding: 0px;
                li {
                    .common-button {
                        padding: 8px 5px;
                    }
                }
            }
        }
        .right-side-col,
        .left-side-col {
            padding: 0px !important;
        }
    }
    @media all and (max-width: 1440px) {
        #template-builder-middle-side-main-container {
            .main-template-middle-container {
                // transform: scale(1);
            }
            .page-no-container:first-child {
                margin: 10px 0px 10px 0px;
            }
            .page-no-container {
                text-align: center;
                margin: 10px 0px 10px 0px;
            }
        }
        .template-builder-card-side {
            h4 {
                margin-left: 0px;
                font-size: 14px;
            }
            ul {
                li {
                    .common-button {
                        i {
                            margin-right: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .ant-collapse-content {
                > .ant-collapse-content-box {
                    padding: 8px;
                }
            }
            .field-is-not-selected-template-right-side {
                h2 {
                    font-size: 14px;
                }
            }
        }
    }
}

.pagination-container {
    width: 612px;
    display: flex;
    justify-content: space-around;
}


@primary-color: #1DA57A;